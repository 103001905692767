<template>
  <div class="workForm">
    <div class="item">
      <div class="item-l">租用房号:</div>
      <div class="item-r">
        <v-input :disabled="true" v-model="postData.name"></v-input>
      </div>
    </div>
    <div class="item">
      <div class="item-l">姓名:</div>
      <div class="item-r">
        <v-input
          :disabled="disabled"
          v-model="form.userName"
          :maxlength="10"
          placeholder="请填写姓名"
        ></v-input>
      </div>
    </div>
    <div class="item">
      <div class="item-l">手机号:</div>
      <div class="item-r">
        <v-input
          :disabled="disabled"
          v-model="form.mobile"
          :maxlength="20"
          placeholder="请填写手机号"
        ></v-input>
      </div>
    </div>
    <div class="item">
      <div class="item-l">公司:</div>
      <div class="item-r">
        <v-input
          :disabled="disabled"
          v-model="form.companyName"
          :maxlength="50"
          placeholder="请填写公司名称"
          >></v-input
        >
      </div>
    </div>
    <div class="item te">
      <div class="item-l">公司简介:</div>
      <div class="item-r">
        <v-input
          :disabled="disabled"
          v-model="form.companyProfile"
          placeholder="请填写公司简介"
          type="textarea"
          :maxlength="150"
          :showWordLimit="true"
        ></v-input>
      </div>
    </div>
    <div :class="['submit', { disabled: disabled }]">
      <div class="submit-l" @click="toMyApply">我的申请</div>
      <div class="block"></div>
      <div class="submit-r" @click="submit">提交申请</div>
    </div>
  </div>
</template>

<script>
import { applyCreateWorkList, createWorkDetail } from "./api.js";

export default {
  name: "workForm",
  data() {
    return {
      disabled: false,
      form: {
        userName: "",
        mobile: "",
        companyName: "",
        companyProfile: "",
      },
      spaceId: "",
      postData: {},
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    openId() {
      return this.$store.state.openId;
    },
  },
  created() {
    this.spaceId = this.$route.query.spaceId;
  },
  mounted() {
    this.getDetails();
  },
  methods: {
    toMyApply() {
      this.$router.push({
        name: "createWork",
      });
    },
    submit() {
      if (this.disabled) {
        this.$toast({ message: "此空间暂未开放", duration: 2000 });
        return;
      }
      let params = {
        spaceId: this.spaceId,
        spaceName: this.postData.name,
        userName: this.form.userName,
        mobile: this.form.mobile,
        companyName: this.form.companyName,
        companyProfile: this.form.companyProfile,
        openId: this.openId,
        tenantId: this.tenantId,
      };
      this.$axios
        .post(`${applyCreateWorkList}`, params, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          if (res.code === 200) {
            this.$toast({ message: "提交申请成功", duration: 2000 });
            this.$router.push({
              name: "createWork",
            });
          } else {
          }
        });
    },

    getDetails() {
      let params = {
        id: this.spaceId,
      };
      this.$axios
        .post(`${createWorkDetail}?spaceId=${this.spaceId}`)
        .then((res) => {
          if (res.code === 200) {
            if (res.data) {
              this.postData = res.data;
              if (!this.postData.name) {
                this.disabled = true;
                this.$toast({ message: "此空间暂未开放", duration: 2000 });
              }
            } else {
              this.disabled = true;
              this.$toast({ message: "此空间暂未开放", duration: 2000 });
            }
          } else {
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.workForm {
  min-height: 100vh;
  padding: 40px;
  box-sizing: border-box;
  overflow: hidden;
  .item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .item-l {
      font-size: 30px;
      width: 160px;
    }
    .item-r {
      flex: 1;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
  .te {
    align-items: flex-start;
    .item-l {
      padding-top: 20px;
    }
  }
  .submit {
    width: 686px;
    height: 80px;
    background: #009aff;
    border-radius: 8px;
    font-size: 32px;
    line-height: 80px;
    text-align: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 100px;
    margin: auto;
    color: #fff;
    display: flex;
    align-items: center;
    .block {
      width: 1px;
      height: 60%;
      background: #fff;
    }
    .submit-l,
    .submit-r {
      flex: 1;
    }
  }
  .disabled {
    background: #999;
    .block {
      width: 1px;
      height: 60%;
      background: #888;
    }
  }
}
</style>
<style lang="less">
.workForm {
}
</style>
